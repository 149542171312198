import { StarRating } from "@components/common";
import { CountryIcon } from "@components/country";
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@components/ui";
import { IconMessage } from "@tabler/icons-react";
import { IWalkingTour } from "@types";
import { getTourUrlWithRefCode } from "@utils/tours";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";

export const TourPlacard = ({ data }: { data: IWalkingTour }) => {
  const { t } = useTranslation();
  const rating = data.ratingAverage
    ? new Intl.NumberFormat("en", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(data.ratingAverage)
    : null;

  return (
    <Card className="flex flex-col justify-between h-full">
      <CardHeader className="relative">
        <div className="flex justify-between gap-3">
          <div>
            <Link href={getTourUrlWithRefCode(data.url)} target="_blank">
              <CardTitle className="text-lg leading-tight min-h-10 line-clamp-2">
                {data.title}
              </CardTitle>
            </Link>
            <div className="flex items-center gap-1 text-xs text-muted-foreground">
              <IconMessage size={15} />
              <span>
                {t("pages.city.tours.ratings", {
                  number: data.testimonialsCount.toLocaleString(),
                })}
              </span>
            </div>
          </div>

          <div className="flex flex-col items-end">
            <span className="text-3xl font-bold text-primary">{rating}</span>
            {rating && (
              <StarRating
                className="text-primary fill-primary -mr-0.5"
                value={Number(rating)}
                size={10}
              />
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent className="relative">
        <div className="relative">
          <div className="absolute top-0 left-0 z-0 w-full h-full rounded-md bg-gradient-to-b from-60% from-transparent to-black" />
          <Image
            src={data.image.medium}
            className="overflow-hidden rounded-md h-[200px] object-cover object-center"
            alt={`tour guide ${data.title}`}
            width={600}
            height={400}
          />
        </div>
        <Badge className="absolute text-xs uppercase top-2 right-8">
          free tour
        </Badge>
        <div className="absolute flex items-end gap-1 px-2 py-1 rounded-lg right-10 bottom-10">
          <div className="flex items-center gap-2">
            {data.langs.map((item) => (
              <CountryIcon
                key={item}
                code={item}
                className="w-6 h-6 shadow-xl"
                isLang
              />
            ))}
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button className="w-full" asChild>
          <Link href={getTourUrlWithRefCode(data.url)} target="_blank">
            Book now
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
};
